import * as React from "react"
import classNames from "classnames"

import {noop} from "../../utils/noop"

/**
 * @typedef {object} TabItemData
 * @property {string} title
 * @property {string|number} id
 *
 */
/**
 * @typedef {object} TabProps
 * @property {TabItemData[]} tabItems
 * @property {(tabId: string|number) => void=} onTabSelect
 * @property {string=} className
 * @property {string=} activeTabId
 */

/**
 * @type {React.FC<TabProps>}
 * @param {TabProps} param0
 */
export const Tab = ({
  className,
  tabItems,
  activeTabId,
  onTabSelect,
  children,
}) => {
  /**
   * @type {(event: React.MouseEvent<HTMLUListElement, MouseEvent>) => void}
   */
  const onClickHandler = React.useCallback(
    event => {
      const dataIndex = event.target.getAttribute("data-index")
      if (event.target.getAttribute("role") === "tab" && dataIndex) {
        onTabSelect && onTabSelect(tabItems[dataIndex].id)
      }
    },
    [onTabSelect, tabItems],
  )

  return (
    <div onKeyDown={noop} onClick={onClickHandler} role="tablist" tabIndex="0">
      <ul className={classNames("sw-tabs", className)}>
        {tabItems.map(({id, title}, index) => (
          <li key={id}>
            <button
              disabled={id === activeTabId}
              tabIndex="0"
              data-index={index}
              className={classNames("tab-item", {active: id === activeTabId})}
              role="tab">
              {title}
            </button>
          </li>
        ))}

        {children}
      </ul>
    </div>
  )
}
