import * as React from "react"
import {css} from "@emotion/core"

import ContentPlaceholder from "../components/base/content-placeholder"
import {SkeletonDashboardCard} from "../components/skeleton-dashboard-card"
import {range} from "../utils/range"

const SkeletonEmployeesDashboard = () => {
  return (
    <div className="employee-dashboard-container skeleton">
      {/* cards  */}
      <div className="employee-dashboard-cards-row">
        <SkeletonDashboardCard />
        <SkeletonDashboardCard />
      </div>

      {/* table scrollable content */}
      <div
        css={css`
          overflow-y: hidden;
          flex: 1;
          overflow-x: auto;
          position: relative;
          height: 100%;
        `}>
        <table className="sw-table sw-table-full" height="100%">
          <thead>
            <tr>
              <th className="fixed-width"> </th>
              <th className="flex-grow-1 flex-b-127">
                <ContentPlaceholder />
              </th>
              <th className="flex-grow-1 flex-b-160">
                <ContentPlaceholder />
              </th>
              <th className="flex-grow-1 flex-b-290">
                <ContentPlaceholder />
              </th>
              <th className="flex-grow-1 flex-b-180">
                <ContentPlaceholder />
              </th>
              <th className="flex-grow-1 flex-b-160">
                <ContentPlaceholder />
              </th>
              <th className="flex-grow-1 flex-b-160">
                <ContentPlaceholder />
              </th>
              <th className="flex-grow-1 flex-b-160">
                <ContentPlaceholder />
              </th>
            </tr>
          </thead>
          <tbody>
            {range(10).map(id => (
              <tr className="skeleton-vertical" key={id}>
                <td className="fixed-width"> </td>
                <td className="flex-grow-1 flex-b-127">
                  <ContentPlaceholder baseWidthPercentage={50} />
                </td>
                <td className="flex-grow-1 flex-b-160">
                  <ContentPlaceholder baseWidthPercentage={50} />
                </td>
                <td className="flex-grow-1 flex-b-290">
                  <ContentPlaceholder baseWidthPercentage={50} />
                </td>
                <td className="flex-grow-1 flex-b-180">
                  <ContentPlaceholder baseWidthPercentage={50} />
                </td>
                <td className="flex-grow-1 flex-b-160">
                  <ContentPlaceholder baseWidthPercentage={50} />
                </td>
                <td className="flex-grow-1 flex-b-160">
                  <ContentPlaceholder baseWidthPercentage={50} />
                </td>
                <td className="flex-grow-1 flex-b-160">
                  <ContentPlaceholder baseWidthPercentage={50} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default SkeletonEmployeesDashboard
