import * as React from "react"

import {Modal, ModalHeader, ModalBody} from "../components/base"

/**
 * @type {React.FunctionComponent<BasicAlertModalProps & import('../components/base/modal').ModalProps>}
 */
const BasicAlertModal = ({
  onCancel,
  title = "Error",
  message = "",
  ...modalProps
}) => {
  return (
    <Modal {...modalProps}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody style={{marginTop: 20}}>
        <div className="text-20 text-w-500">{message}</div>
      </ModalBody>
    </Modal>
  )
}

export default BasicAlertModal

/**
 * @typedef {object} BasicAlertModalProps
 * @property {string=} title
 * @property {string=} message
 */
