/**
 * @description the card is used in employee dashboard,
 * it displays the restricted count number
 */
import * as React from "react"

import ContentPlaceholder from "../components/base/content-placeholder"

export const SkeletonDashboardCard = () => {
  return (
    <div className="dashboard-card skeleton-horizontal">
      <div className="left column-center w-88">
        <div>
          <ContentPlaceholder width={60} style={{margin: "10px 0"}} />
          <ContentPlaceholder width={80} style={{margin: "10px 0"}} />
        </div>
      </div>
      <div className="right">
        <ContentPlaceholder width={85} height={62} />
      </div>
    </div>
  )
}

export default SkeletonDashboardCard
