import * as React from "react"
import {navigate} from "gatsby"
import Loadable from "@loadable/component"

import DefaultPlaceholder from "../components/default-placeholder"
import {IconButton} from "../components/base/icon-button"
import {Tab} from "../components/base/tab"
import {useModalProps} from "../hooks/modal"
import SkeletonEmployeeDashboard from "../components/skeleton-employee-dashboard"
import BasicAlertModal from "../components/basic-alert-modal"
import SEO from "../layouts/seo"

import {useWorkspaceSettings} from "../services/workspace"
import {
  invalidateEligibleUsers,
  invalidateUsers,
  invalidateRestricted,
} from "../services/user"

import "./app-employees.scss"

const LoadableAddEmployeeModal = Loadable(() =>
  import("../containers/add-employee-modal"),
)
const LoadableEmployeeDashboard = Loadable(
  () => import("../containers/employee-dashboard"),
  {
    fallback: <SkeletonEmployeeDashboard />,
  },
)
const LoadableRoasterDashboard = Loadable(
  () => import("../containers/roaster-mgmt-dashboard"),
  {
    fallback: <SkeletonEmployeeDashboard />,
  },
)

const tabItems = [
  {
    id: "employee-list",
    title: "Employee List",
  },
  {
    id: "roster-management",
    title: "Roster Management",
  },
]

const getTabIdFromURLSearchParams = search => {
  const searchParams = new URLSearchParams(search)
  return searchParams.get("tab") || searchParams.get("tabId") || tabItems[0].id
}

/**
 * @type {React.FunctionComponent<PageProps>}
 * @param {PageProps} props
 */
const EmployeesPage = props => {
  const {
    isLoading: isWorkspaceSettingsLoading,
    data: workspaceSettings,
    error,
  } = useWorkspaceSettings()

  return (
    <div className="app-employee-page-container dashboard-page-container">
      <SEO title="Employees Dashboard" />
      <DefaultPlaceholder forceError error={error} data={tabItems}>
        <EmployeesPageContent
          workspaceSettings={workspaceSettings}
          isWorkspaceSettingsLoading={isWorkspaceSettingsLoading}
          location={props.location}
        />
      </DefaultPlaceholder>
    </div>
  )
}

const EmployeesPageContent = ({
  workspaceSettings,
  isWorkspaceSettingsLoading,
  location,
}) => {
  const alertDialog = useModalProps(false)

  // import/add employee dialog props
  const dialog = useModalProps(false)
  const onOpenDialog = () => {
    if (
      !workspaceSettings ||
      !workspaceSettings.backToWorkSurvey?.surveyId ||
      !workspaceSettings.dailySurvey?.surveyId
    ) {
      alertDialog.openModal()
    } else {
      dialog.openModal()
    }
  }

  const [activeTabId, setActiveTabId] = React.useState(() =>
    getTabIdFromURLSearchParams(location.search),
  )

  const onTabChanged = React.useCallback(tabId => {
    setActiveTabId(tabId)
    navigate("?tab=" + tabId)
  }, [])
  React.useEffect(() => {
    // invalidate queries when changing active tab
    invalidateEligibleUsers()
    invalidateUsers()
    invalidateRestricted()
  }, [activeTabId])

  return (
    <>
      <Tab
        className="p-relative"
        activeTabId={activeTabId}
        onTabSelect={onTabChanged}
        tabItems={tabItems}>
        {isWorkspaceSettingsLoading ? null : (
          <li className="add-employee-icon">
            <IconButton
              icon="btn-upload"
              aria-label="open dialog for adding employee"
              size={30}
              onClick={onOpenDialog}
            />
          </li>
        )}
      </Tab>

      <div
        className="h-full flex-1"
        hidden={activeTabId !== tabItems[0].id}
        aria-hidden={activeTabId !== tabItems[0].id}>
        <LoadableEmployeeDashboard />
      </div>
      <div
        hidden={activeTabId !== tabItems[1].id}
        aria-hidden={activeTabId !== tabItems[1].id}
        className="h-full flex-1">
        <LoadableRoasterDashboard />
      </div>

      <BasicAlertModal
        message="Please make sure the survey is setup before inviting employees"
        {...alertDialog.getProps()}
      />

      {dialog.show ? (
        <LoadableAddEmployeeModal
          {...dialog.getProps()}
          onCancel={dialog.closeModal}
        />
      ) : null}
    </>
  )
}

export default EmployeesPage

/**
 * @typedef {object} LocationState
 * @property {string=} name
 * @property {string=} id
 */
/**
 * @typedef {import('gatsby').PageProps<object, object, LocationState>} PageProps
 */
